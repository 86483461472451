import React, { useCallback, useEffect, useState } from "react";

function Past() {


  // getting json file and building an array
  const [projects, setProjects] = useState([]);

  const fetchJSONDataFrom = useCallback(async (path) => {
    const response = await fetch(path, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });
    const data = await response.json();
    setProjects(data);
  }, []);

  useEffect(() => {
    fetchJSONDataFrom("data.json");
  }, [fetchJSONDataFrom]);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // creating new array
  var pastProjects = projects.filter(function (el) {
    const x = new Date(el.date);
    const y = new Date();
    const z = addDays(x,2);
    return (y > z ) ;
  });

  pastProjects.sort((x, y) => new Date(y.date) - new Date(x.date));


  return (
    <>
    <div class="header"><a href="/"><img class="websitelogo" src="./websitelogo.png" alt=""></img></a></div>
    <div className="home">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-12">
            <h1>Past NFT Sales</h1><br/>
            
            {pastProjects.map((project) => (
              <>
               <div class="projectsection">
                  <span class="projecttitle">{project.name}</span><br/>
                  <span class="projectdescription">{project.description}</span><br/>
            
                  <img class="thumb" src={project.image1} alt=""></img>
                  <img class="thumb" src={project.image2} alt=""></img>
                  <img class="thumb" src={project.image3} alt=""></img>
                  <br/>
                  <span class="projectprice">{project.price}</span><br/>
                  <span class="projecttotal">{project.count}</span><br/><br/>
                  <span class="pastprojectdate">{project.datetext}</span><br/>
                  <span class="px-2">{project.timetext}</span><br/><br/>
                  <span class="px-2"><a target="_blank" rel="noreferrer" href={project.discord}><img class="mediaicon" src="./images/icons/discord.svg" alt=""></img></a></span>
                  <span class="px-2"><a target="_blank" rel="noreferrer" href={project.twitter}><img class="mediaicon" src="./images/icons/twitter.svg" alt=""></img></a></span><br/>
                  <span class="projecturl"><a target="_blank" rel="noreferrer" href={project.websiteurl}>{project.websitetext}</a></span><br/>
            </div>
            </>
            
            ))}
            <div class="py-5 fs-4 text-center"><a href="/">&#60; Back Home</a></div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Past;