import React from "react";

function Token() {
  return (
    <>
    <div class="header"><a href="/"><img class="websitelogo" src="./websitelogo.png" alt=""></img></a></div>
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-8">
            <h1 class="font-weight-light">NFTmat Token</h1>
            <p class="fs-4 py-3">
              NFTmat token is a <b>BEP-20</b> token that runs on the <b>Binance Smart Chain</b> <br/><br/>

              1 NFTmat = 0.0000001 BNB (0.00006 USD)
              <br/><br/>

              The token contract address is <a target = "_blank" rel="noreferrer" href="https://bscscan.com/token/0x6be638ffc736a30b931e507deb95cfc54049718f">0x6be638ffc736a30b931e507deb95cfc54049718f</a>
              <br/>
              
            </p>
            <h2 class="font-weight-light">Where can I buy NFTmat tokens? </h2>
            <p class="fs-4 py-3">
              You can buy NFTmat token on <a target ="_blank" rel="noreferrer" href="https://pancakeswap.finance/swap?outputCurrency=0x6be638ffc736a30b931e507deb95cfc54049718f" >Pancakeswap</a> 
            </p>
            <h2 class="font-weight-light">Is there any other way to acquire NFTmat tokens? </h2>
            <p class="fs-4 py-3">
                If your project gets listed on NFTmat.com, then you are entitled to free NFTmat tokens (worth $20-$100) if you choose to gift us one item from your collection. You will be receiving your free tokens after the transfer of the item is completed. 
              <br/><br/>
              Please contact us on <a target="_blank" rel="noreferrer" href="https://twitter.com/NFTmat">Twitter</a> or <a target="_blank" rel="noreferrer" href="https://discord.gg/XjFJYz9nTp">Discord</a> to claim your free tokens. 
            </p>

            <div class="py-5 fs-4 text-center"><a href="/">&#60; Back Home</a></div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Token;