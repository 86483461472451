import React from "react";

function About() {
  return (
    <>
    <div class="header"><a href="/"><img class="websitelogo" src="./websitelogo.png" alt=""></img></a></div>
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-8">
            <h1 class="font-weight-light">About NFTmat</h1>
            <p class="fs-4">
              NFTmat is an online platform where you can find out about upcoming NFT sales, see rankings and recent sales activity.
            </p>
            <p class="fs-4">
              NFTs (Non-fungible Tokens) are a unit of data that uses blockchain to certify the uniqueness and ownership of a digital asset. NFTs give artists more control over their art, enabling them to sell directly to the public and cutting out the intermediary.
            </p>
            <h3 class="font-weight-light">Disclaimer</h3>
            <p class="fs-4">
               The projects listed on NFTmat are submitted by project owners, therefore we cannot validate the claims or the trustworthiness of the projects listed. 
            </p>
            <p class="fs-4">
              Any information provided on NFTmat (including the featured projects) should not be considered as financial or investment advice. Please do your own due diligence before participating in the projects. 
            </p>
            <p class="fs-4">
              The information posted on NFT may be inaccurate, incomplete or outdated. Projects may change the details of the sale and/or other information without us knowing. Please verify all information from official project channels.
            </p>
            <h3 class="font-weight-light">Contact</h3>
            <p class="fs-4">
              If you have any questions or requests regarding projects listed on NFTmat, please email us at info@nftmat.com
            </p>
            <p class="fs-4 box pt-4 ps-4 pb-2">
              If you would like to support us, you can send ETH/BSC tokens to our team wallet 
              <br/>0x53C6C2b3729dDB0cE2A7FC49181622B05D227Fd1
            </p>
          
            
            <div class="py-5 fs-4 text-center"><a href="/">&#60; Back Home</a></div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default About;