import React, { useState, useEffect } from "react";


function Rankings() {

    const slug = ['world-of-women-nft', 'cool-cats-nft']; 

    const url1 = "https://api.opensea.io/collection/world-of-women-nft/?format=json";
    const url2 = "https://api.opensea.io/collection/cool-cats-nft/?format=json";


    const [nftData, setnftData] = useState({});
    const [nftData2, setnftData2] = useState({});


    useEffect(() => {
        getNFTWithFetch(url1, setnftData);
        getNFTWithFetch(url2, setnftData2);
    }, []);

    const getNFTWithFetch = async (url, set) => {
        const response = await fetch(url);
        const jsonData = await response.json();
        set(jsonData);
    };

    let data = [];

    data.push({ 
        floor_price: nftData.collection && nftData.collection.stats.floor_price, 
        name: nftData.collection && nftData.collection.primary_asset_contracts[0].name
     });

     data.push({ 
        floor_price: nftData2.collection && nftData2.collection.stats.floor_price, 
        name: nftData2.collection && nftData2.collection.primary_asset_contracts[0].name
     });
   


  return (
    <>
    <div class="header"><a href="/"><img class="websitelogo" src="./websitelogo.png"></img></a></div>
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-8">
            <h1 class="font-weight-light">Rankings</h1><br/>
         
            <br/>

          {data.map((project) => (<div>{project.name} - {project.floor_price}</div>))}
          {/* {console.log(data)} */}
 


            
            <br/>
            <br/>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Rankings;