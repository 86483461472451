import React, { useCallback, useEffect, useState } from "react";
import $ from 'jquery';

function Home() {


  // getting json file and building an array
  const [projects, setProjects] = useState([]);

  const fetchJSONDataFrom = useCallback(async (path) => {
    const response = await fetch(path, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });
    const data = await response.json();
    setProjects(data);
  }, []);

  useEffect(() => {
    fetchJSONDataFrom("data.json");
  }, [fetchJSONDataFrom]);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // creating new array
  var currentProjects = projects.filter(function (el) {
    const x = new Date(el.date);
    const y = new Date();
    const z = addDays(x,2);
    return (y < z && el.featured === "no") ;
  });

  currentProjects.sort((x, y) => new Date(x.date) - new Date(y.date));

  var currentfeaturedProjects = projects.filter(function (el) {
    const x = new Date(el.date);
    const y = new Date();
    const z = addDays(x,2);
      return (y < z && el.featured === "yes") ;
  });
  
  currentfeaturedProjects.sort((x, y) => new Date(x.date) - new Date(y.date));


  // effects

  // $(function(){  // $(document).ready shorthand
  //   $('.monster').fadeIn('slow');
  // });
  
  $(document).ready(function() {
      
      /* Every time the window is scrolled ... */
      $(window).scroll( function(){
      
          /* Check the location of each desired element */
          $('.hideme').each( function(i){
              
              var bottom_of_object = $(this).position().top + $(this).outerHeight();
              var bottom_of_window = $(window).scrollTop() + $(window).height();
              
              /* If the object is completely visible in the window, fade it it */
              if( bottom_of_window > bottom_of_object ){
                  
                  $(this).animate({'opacity':'1'},400);
                      
              }
              
          }); 
          $('.hideme2').each( function(i){
              
            var bottom_of_object = $(this).position().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();
            
            /* If the object is completely visible in the window, fade it it */
            if( bottom_of_window > bottom_of_object ){
                
                $(this).animate({'opacity':'1'},800);
                    
            }
            
           });
           $('.hideme3').each( function(i){
              
            var bottom_of_object = $(this).position().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();
            
            /* If the object is completely visible in the window, fade it it */
            if( bottom_of_window > bottom_of_object ){
                
                $(this).animate({'opacity':'1'},1200);
                    
            }
            
           });
      
      });
      
  });

  return (
    <>
    <div class="header"><a href="/"><img class="websitelogo" src="./websitelogo.png"></img></a></div>
    <div className="home">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-12">
            <h1>Upcoming NFT Sales</h1><br/>

            {currentfeaturedProjects.map((project) => (
              <>
              <div class="featured">
              <span class="featuredtext">FEATURED</span>
              <div class="projectsection">
                  <span class="projecttitle">{project.name}</span><br/>
                  <span class="projectdescription">{project.description}</span><br/>
            
                  <img class="thumb" src={project.image1}></img>
                  <img class="thumb" src={project.image2}></img>
                  <img class="thumb" src={project.image3}></img>
                  <br/>
                  <span class="projectprice">{project.price}</span><br/>
                  <span class="projecttotal">{project.count}</span><br/><br/>
                  <span class="projectdate">{project.datetext}</span><br/>
                  <span class="px-2">{project.timetext}</span><br/><br/>
                  <span class="px-2"><a target="_blank" href={project.discord}><img class="mediaicon" src="./images/icons/discord.svg"></img></a></span>
                  <span class="px-2"><a target="_blank" href={project.twitter}><img class="mediaicon" src="./images/icons/twitter.svg"></img></a></span><br/>
                  <span class="projecturl"><a target="_blank" href={project.websiteurl}>{project.websitetext}</a></span><br/>
              </div>
            </div>
            </>
            
            ))}
            
            {currentProjects.map((project) => (
              <>
               <div class="projectsection">
                  <span class="projecttitle">{project.name}</span><br/>
                  <span class="projectdescription">{project.description}</span><br/>
            
                  <img class="thumb hideme" src={project.image1}></img>
                  <img class="thumb hideme2" src={project.image2}></img>
                  <img class="thumb hideme3" src={project.image3}></img>
                  <br/>
                  <span class="projectprice">{project.price}</span><br/>
                  <span class="projecttotal">{project.count}</span><br/><br/>
                  <span class="projectdate">{project.datetext}</span><br/>
                  <span class="px-2">{project.timetext}</span><br/><br/>
                  <span class="px-2"><a target="_blank" href={project.discord}><img class="mediaicon" src="./images/icons/discord.svg"></img></a></span>
                  <span class="px-2"><a target="_blank" href={project.twitter}><img class="mediaicon" src="./images/icons/twitter.svg"></img></a></span><br/>
                  <span class="projecturl"><a target="_blank" href={project.websiteurl}>{project.websitetext}</a></span><br/>
            </div>
            </>
            
            ))}

          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;