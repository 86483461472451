import React from "react";
import { withRouter } from "react-router-dom";

function Navigation(props) {
  return (
    <div className="navigation">
      <nav class="navbar navbar-light fixed-top-new">
        <div class="container-fluid">
          <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header justify-content-end">
              <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li class="nav-item py-3 fs-3">
                  <a class="nav-link active" href="/">Home</a>
                </li>
                <li class="nav-item py-3 fs-3">
                  <a class="nav-link active" href="./submit">Submit Project</a>
                </li>
                <li class="nav-item py-3 fs-3">
                  <a class="nav-link active" href="./past-nft-sales">Past NFT Sales</a>
                </li>
                <li class="nav-item py-3 fs-3">
                  <a class="nav-link active" href="./token">NFTmat Token</a>
                </li>
                <li class="nav-item py-3 fs-3">
                  <a class="nav-link active" href="./about">About NFTmat</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default withRouter(Navigation);