import React from "react";

function Submit() {
  return (
    <>
    <div class="header"><a href="/"><img class="websitelogo" src="./websitelogo.png" alt=""></img></a></div>
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
          <div class="col-lg-8">
            <h1 class="font-weight-light">Submit your project</h1><br/>
            <p class="fs-4">
              <b>NFTmat</b> is visited by thousands of NFT enthusiasts from all over the world. 
              <br/>
              Thank you for your interest in listing your project on our website.
            </p><br/>
            <div class="box pt-4 ps-4 pb-2">
            <h3>Requirements:</h3>
              <p class="fs-4">
                - <a class="fw-bold" target="_blank" rel="noreferrer" href="https://twitter.com/NFTmat">Follow us</a> on Twitter</p>
              <p class="fs-4">
                - <a class="fw-bold" target="_blank" rel="noreferrer" href="https://discord.gg/XjFJYz9nTp">Join</a> our Discord</p>
            </div>
            
            
                <br/>
            <p class="fs-4">
              Once requirements are fulfilled, you can use <a class="fw-bold" href="https://forms.gle/gedWkyUGcZtUTcGu5">this form</a> to submit your project.
            </p>
            <h3 class="py-4">Claim your free NFTmat tokens</h3>
            <p class="fs-4">
              If your project gets listed on NFTmat.com, then you are entitled to <a href="./token"><b>free NFTmat tokens</b></a> (worth $20-$100) if you choose to gift us one item from your collection. You will be receiving your free tokens after you complete the transfer of the item. 
            </p>
            <h3 class="py-4">Featured Listings</h3>
            <p class="fs-4">
              If you would like to have your project listed as a <i>featured project</i>, please contact us on Twitter.
            </p>
            <br/>
            <div class="py-5 fs-4 text-center"><a href="/">&#60; Back Home</a></div>
            <br/>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Submit;