import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navigation, Footer, Home, About, Submit, Past, Rankings, Token } from "./components";

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/submit" exact component={() => <Submit />} />
          <Route path="/past-nft-sales" exact component={() => <Past />} />
          <Route path="/rankings" exact component={() => <Rankings />} />
          <Route path="/token" exact component={() => <Token />} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;