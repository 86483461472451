import React from "react";

function Footer() {
  return (
    <div className="footer">
      <footer class="py-4 bg-dark">
        <div class="container">
          <div class="text-center pb-4">
            <span><a class="footerlink px-2" href="/">Home</a></span>
            <span><a class="footerlink px-2" href="./submit">Submit Project</a></span>
            <span><a class="footerlink px-2" href="./past-nft-sales">Past NFT Sales</a></span>
            <span><a class="footerlink px-2" href="./about">About</a></span>
          </div>
          <div class="text-center pb-4"><a class="footerlink px-2" href="./token">NFTmat Token</a></div>
          <div class="text-center">
            <a class="px-3" target="_blank" rel="noreferrer" href="https://discord.gg/XjFJYz9nTp"><img src="./images/icons/discord2.svg" alt=""></img></a>
            <a class="px-3" target="_blank" rel="noreferrer" href="https://twitter.com/NFTmat"><img src="./images/icons/twitter2.svg" alt=""></img></a> 
           
          </div>
          <p class="m-0 pt-3 fs-6 text-center text-muted">
            NFTMAT &copy; 2021
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;